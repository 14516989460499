// import logo from "./logo.svg";
import "./App.css";
import Dashboard from "./Components/Dashboards";

function App() {
  return (
    <div className="portfolio">
      <Dashboard />
    </div>
  );
}

export default App;
